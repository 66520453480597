:root {
  --phone: 320px;
  --largephone: 425px;
  --tablet: 768px;
  --desktop: 1024px;
  --largedesktop: 1440px;

  --block-shadow: 0 0 15px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  --block-shadow-bottom: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
  --block-shadow-top: 0 -18px 35px rgba(50, 50, 93, 0.1), 0 -8px 15px rgba(0, 0, 0, 0.07);
  --block-shadow-min: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  --block-shadow-hover: var(--block-shadow-bottom);
  --floating-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
