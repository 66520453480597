body {
  --body-background: var(--blue2);
  --body-font-color: var(--gray8);
  --body-font-size: 14px;
  --body-font-weight: 300;
  --body-line-height: 1.4;
  --body-font-family: var(--default-font-family);
  --body-margin: 0;
  --body-margin-top: 51px;

  @media (--gte-tablet) {
    --body-margin-top: 76px;
  }
}
