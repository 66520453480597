.container {
  opacity: 0;
  position: fixed;
  z-index: 199;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
}

.base {
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.2), 0 0 24px 2px rgba(0, 0, 0, 0.14),
    0 0 30px 5px rgba(0, 0, 0, 0.12);
  transition: transform 250ms ease-in-out 0s;
  background-color: var(--gray1);
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  margin-top: 20px;
}

.body {
  padding: 20px;
  max-width: 800px;

  @media (--gt-largephone) {
    padding: 30px;
  }
}

.window {
  overflow: hidden;
}

.loading {
  @mixin loader;
}

.disabled {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  cursor: not-allowed;
}

.disabledLoading {
  composes: disabled;
  cursor: progress;
}
