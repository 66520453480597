:root {
  --white: #fff;
  --off-white: #fefefe;
  --dirty-white: #edebec;

  --black: #0a0a0a;
  --off-black: #333;

  --gray0: #fafafa;
  --gray1: #f7f7f7;
  --gray2: #f3f3f4;
  --gray3: #ebebeb;
  --gray4: #ddd;
  --gray5: #cacaca;
  --gray6: #8a8a8a;
  --gray7: #8a8a8a;
  --gray8: #6e6362;
  --gray9: #666;

  --purple1: #757294;
  --purple2: #aa7fb8;
  --purple3: #8463b9;
  --purple4: #995fa7;
  --purple5: #722a88;
  --purple6: #533747;
  --purple7: #48334e;
  --purple8: #302238;
  --purple9: #1a1450;

  --blue0: #f8f9fd;
  --blue1: #f0f8ff;
  --blue2: #f5f6fa;
  --blue3: #eaebf0;
  --blue4: #e4e7f2;
  --blue5: #d7ebfe;
  --blue6: #4a90e2;
  --blue7: #1a1352;

  /* D7F7C2 */
  --green1: #d7f7c2;
  --green2: #afdbd8;
  --green3: #48e79a;
  --green4: #5cb85c;
  --green5: #064;
  --green6: #397b79;
  --green7: #005237;

  --lighter-orange: #fffae6;
  --dark-orange: #ffab00;
  --darker-orange: #ff8b00;

  --light-orange: #e89781;
  --orange: #e07356;
  --red0: #ffe0e0;
  --red: #f00;

  --lighter-pink: #f5e6f4;
  --light-pink: #f1d9ee;
  --pink: #d183c9;
  --dark-pink: #c688c5;

  --primary-color: var(--purple5);
  --secondary-color: var(--gray4);

  --input-color: var(--gray8);
  --input-disabled-color: var(--gray6);
  --input-background: var(--white);
  --input-disabled-background: var(--gray3);
  --input-border-color: var(--gray4);
  --input-hover-border-color: var(--gray6);

  --input-alt-color: var(--black);
  --input-alt-background: var(--blue2);
  --input-alt-border-color: var(--gray3);
  --input-alt-hover-border-color: var(--gray5);

  --body-background: var(--blue2);

  --link-color: var(--primary-color);
  --link-hover-color: var(--purple6);
}
