.base {
  composes: base from '../index.css';
  transform: translate(100%, 0px);
  max-width: 95%;
  margin-top: 0;
  right: 0;

  @media (--gt-tablet) {
    max-width: 90%;
  }

  @media (--gt-desktop) {
    max-width: 75%;
  }
}

.enter,
.enterDone {
  opacity: 0;
}

.enterActive,
.enterDone {
  opacity: 1;

  > section {
    transform: translate(0, 0);
  }
}

.exit {
  opacity: 1;
  transition: opacity 400ms;

  > section {
    transform: translate(100%, 0);
  }
}

.exitActive,
.exitDone {
  opacity: 0;
}
