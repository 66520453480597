@import-normalize;

/*
  Document

  1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or
  `border`.
  2. Change the default font family in all browsers.
  3. Correct the line height in all browsers.
  4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
     we force a non-overlapping, non-auto-hiding scrollbar to counteract.
  5. Change the default tap highlight to be completely transparent in iOS.
*/

html {
  box-sizing: border-box; /* 1 */
  font-family: sans-serif; /* 2 */
  line-height: var(--body-line-height); /* 3 */
  -ms-overflow-style: scrollbar; /* 4 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 5 */
}

body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  color: var(--body-font-color);
  line-height: var(--body-line-height);
  background-color: var(--body-background);
  margin: var(--body-margin);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: inherit; /* 1 */
}

/*
  Remove top margins from headings. By default, `<h1>`-`<h6>` all receive top and bottom margins. We
  nuke the top margin for easier control within type scales as it avoids margin collapsing.
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

span {
  display: inline-block;
}

a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 500;
  background-color: transparent;

  &:hover {
    color: var(--link-hover-color);
    text-decoration: none;
  }
}

figure {
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

label {
  @mixin label;
}

fieldset {
  /*
    Browsers set a default `min-width: min-content;` on fieldsets,
    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    So we reset that to ensure fieldsets behave more like a standard block element.
    See https://github.com/twbs/bootstrap/issues/12359
    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  */
  min-width: 0;
  padding: 0;
  margin: 20px 0 0;
  border: 0;
}

/*
  1. Correct the text wrapping in Edge and IE.
  2. Correct the color inheritance from `fieldset` elements in IE.
*/
legend {
  display: block;
  width: 100%;
  max-width: 100%; /* 1 */
  border-bottom: 1px solid var(--dirty-white);
  padding-bottom: 6px;
  margin-bottom: 22px;
  line-height: inherit;
  color: inherit; /* 2 */
  white-space: normal; /* 1 */

  small {
    color: var(--gray7);
    margin-left: 10px;
  }
}

table {
  border-collapse: collapse;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid var(--gray3);
  border-left: 0;
  margin: 25px 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; /* Undo browser default */
}

b,
strong {
  font-weight: bolder; /* Add the correct font weight in Chrome, Edge, and Safari */
}

mark {
  background-color: var(--lighter-orange);
  position: relative;

  &::before {
    content: '';
    background-color: var(--lighter-orange);
    border-radius: 6px;
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -6px;
    right: -6px;
    z-index: -1;
  }

  > .definition {
    background-color: var(--lighter-orange);
  }
}
