/* stylelint-disable value-list-comma-newline-after,value-list-comma-space-after */

@font-face {
  font-family: MuseoSansRounded;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('MuseoSansRounded'), url('/fonts/museosansrounded-300-webfont.woff2') format('woff2'),
    url('/fonts/museosansrounded-300-webfont.woff') format('woff');
}

@font-face {
  font-family: MuseoSansRounded;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('MuseoSansRounded'), url('/fonts/museosansrounded-500-webfont.woff2') format('woff2'),
    url('/fonts/museosansrounded-500-webfont.woff') format('woff');
}

@font-face {
  font-family: MuseoSansRounded;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local('MuseoSansRounded'), url('/fonts/museosansrounded-100-webfont.woff2') format('woff2'),
    url('/fonts/museosansrounded-100-webfont.woff') format('woff');
}

:root {
  --default-font-family: 'MuseoSansRounded', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  --article-font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
}
