/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
@define-mixin loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    box-sizing: border-box;
    display: block;
    font-size: 0;
    color: var(--primary-color);
    width: 38px;
    height: 38px;

    > div {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      float: none;
      background-color: currentColor;
      border: 0 solid currentColor;
      position: absolute;
      top: 50%;
      left: 50%;
      background: transparent;
      border-style: solid;
      border-width: 3px;
      border-radius: 100%;
      animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;

      &:first-child {
        position: absolute;
        width: 38px;
        height: 38px;
        border-right-color: transparent;
        border-left-color: transparent;
      }

      &:last-child {
        width: 18px;
        height: 18px;
        border-top-color: transparent;
        border-bottom-color: transparent;
        animation-duration: 0.5s;
        animation-direction: reverse;
      }
    }
  }

  @keyframes ball-clip-rotate-multiple-rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

@define-mixin loader-sm {
  @mixin loader;
  @mixin loader__sm;
}

@define-mixin loader__sm {
  > div {
    width: 24px;
    height: 24px;

    > div {
      border-width: 2px;

      &:first-child {
        width: 24px;
        height: 24px;
      }

      &:last-child {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@define-mixin loader__2x {
  > div {
    width: 64px;
    height: 64px;

    > div {
      border-width: 5px;

      &:first-child {
        width: 64px;
        height: 64px;
      }

      &:last-child {
        width: 32px;
        height: 32px;
      }
    }
  }
}
