.base {
  composes: base from '../index.css';
  transform: translate(0, 100%);
}

.enter,
.enterDone {
  opacity: 0;
}

.enterActive,
.enterDone {
  opacity: 1;

  > section {
    transform: translate(0, 0);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.exit {
  opacity: 1;
  transition: opacity 400ms;

  > section {
    transform: translate(0, 100%);
  }
}

.exitActive,
.exitDone {
  opacity: 0;
}
